export const TITLE_WEBSITE = 'Benchmark Mineral Intelligence'

export const META_DESCRIPTION_WEBSITE =
  'World leading supply chain & energy transition intelligence. Lithium, Nickel, Cobalt, Graphite, Batteries, Electric Vehicles, Rare Earths and Permanent Magnets.'

export const TWITTER_IMAGE =
  'https://res.cloudinary.com/benchmarkminerals/image/upload/dpr_auto,f_avif,q_auto,g_auto/v1680641859/assets/social/Benchmark_Logo_Twitter_iiosvj.png'

export const LINKEDIN_IMAGE =
  'https://res.cloudinary.com/benchmarkminerals/image/upload/dpr_auto,f_avif,q_auto,g_auto/v1680641799/assets/social/Benchmark_Logo_LinkedIn_osauqc.png'

export const TWITTER_HANDLE = '@benchmarkmin'
